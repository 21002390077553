import React from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import Layout from "../components/layout"
import Seo from "../components/seo"

const News = ({ data }) => {
  return (
    <Layout>
      <Seo title={"Pressnews"} description={"All pressnews list"} />
      <section className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="font-weight-normal pb-4">
              <Link to={"/news/"}>&#60; Back to News main page</Link>
            </div>
            <div className="d-block">
              <h1 className="pb-2">EnerBank USA in the News</h1>
            </div>
            <hr className="mb-4" />
          </div>
        </div>
        {data.wpgraphql.newsmediaplural.edges[0].node.newsMedia.edges.map(
          (item, i) =>
            i > 3 && (
              <div className="row" key={item.node.id}>
                <div className="col-12 my-3 blog-post border-bottom pb-4">
                  <div className="d-lg-flex">
                    <h4
                      className="mb-2"
                      dangerouslySetInnerHTML={{ __html: item.node.title }}
                    />
                    <h5
                      className="mb-2 ml-auto font-weight-normal text-lg-right"
                      style={{ minWidth: "150px" }}
                    >
                      {moment(item.node.date).format("MMMM D, Y")}
                    </h5>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                    className="mb-auto"
                  />
                  <a
                    href={item.node.newsmedia.newslink.url}
                    rel="noreferrer"
                    className="btn-link p-0"
                  >
                    Read more
                  </a>
                </div>
              </div>
            )
        )}
      </section>
    </Layout>
  )
}
export default News

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
      }

      newsmediaplural(where: { name: "pressnews" }) {
        edges {
          node {
            id
            name
            newsMedia(
              first: 1000
              where: { orderby: { order: DESC, field: DATE } }
            ) {
              edges {
                node {
                  id
                  title
                  date
                  excerpt
                  slug
                  newsmedia {
                    fieldGroupName
                    newslink {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
